<template>
<div>
    <div class="row">
        <div class="col-sm-12">
            <div class="page-title-box">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="alert alert-success alert-has-icon">
                            <div class="alert-icon"><i class="far fa-lightbulb"></i></div>
                            <div class="alert-body">
                                <div class="alert-title">Halo {{user.nama_lengkap}} </div>
                                Selamat datang di dashboard admin Rudaya.
                            </div>

                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>

             <!-- <div class="page-title-box">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <h4 class="page-title m-0">Dashboard</h4>
                    </div>
                    <div class="col-md-4">
                        <div class="float-right d-none d-md-block">

                        </div>
                    </div>
            </div> -->

        </div>
    </div>
    <!-- end page title -->

    <!-- Untuk Dashboard Admin -->
    <!-- <div class="row" v-if="user.level == 'Admin'">
        <div class="col-xl-3 col-md-6">
            <div class="card bg-primary mini-stat text-white">
                <div class="p-3 mini-stat-desc">
                    <div class="clearfix">
                        <h6 class="text-uppercase mt-0 float-left text-white-50">Pengguna Terdaftar</h6>
                        <h4 class="mb-3 mt-0 float-right">{{ data.user_terdaftar }}</h4>
                    </div>
                    <div>
                        <span class="badge badge-light text-info"> {{ data.user_terdaftar_bulan_lalu }}</span> <span class="ml-2">Bulan lalu</span>
                    </div>
                </div>
                <div class="p-3">
                    <div class="float-right">
                        <a href="#" class="text-white-50"><i class="mdi mdi-cube-outline h5"></i></a>
                    </div>
                    <p class="font-14 m-0">Bulan ini : {{ data.user_terdaftar_bulan_ini }}</p>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-md-6">
            <div class="card bg-info mini-stat text-white">
                <div class="p-3 mini-stat-desc">
                    <div class="clearfix">
                        <h6 class="text-uppercase mt-0 float-left text-white-50">Merchant Terdaftar</h6>
                        <h4 class="mb-3 mt-0 float-right">{{ data.merchant_terdaftar }}</h4>
                    </div>
                    <div>
                        <span class="badge badge-light text-danger"> {{ data.merchant_terdaftar_bulan_lalu }} </span> <span class="ml-2">Bulan lalu</span>
                    </div>
                </div>
                <div class="p-3">
                    <div class="float-right">
                        <a href="#" class="text-white-50"><i class="mdi mdi-buffer h5"></i></a>
                    </div>
                    <p class="font-14 m-0">Bulan ini : {{ data.merchant_terdaftar_bulan_ini }}</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-warning mini-stat text-white">
                <div class="p-3 mini-stat-desc">
                    <div class="clearfix">
                        <h6 class="text-uppercase mt-0 float-left text-white-50">Pemesanan Dilakukan</h6>
                        <h4 class="mb-3 mt-0 float-right">{{ data.pesanan }}</h4>
                    </div>
                    <div>
                        <span class="badge badge-light text-primary">{{ data.pesanan_bulan_lalu }}</span> <span class="ml-2">Bulan lalu</span>
                    </div>
                </div>
                <div class="p-3">
                    <div class="float-right">
                        <a href="#" class="text-white-50"><i class="mdi mdi-tag-text-outline h5"></i></a>
                    </div>
                    <p class="font-14 m-0">Bulan ini : {{ data.pesanan_bulan_ini }}</p>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-md-6">
            <div class="card bg-success mini-stat text-white">
                <div class="p-3 mini-stat-desc">
                    <div class="clearfix">
                        <h6 class="text-uppercase mt-0 float-left text-white-48">Merchant Harus Validasi</h6>
                        <h4 class="mb-3 mt-0 float-right">{{ data.merchant_perlu_validasi }}</h4>
                    </div>
                    <div>
                        <span class="badge badge-light text-info"> {{ data.merchant_perlu_validasi_bulan_lalu }} </span> <span class="ml-2">Bulan lalu</span>
                    </div>
                </div>
                <div class="p-3">
                    <div class="float-right">
                        <a href="#" class="text-white-50"><i class="mdi mdi-briefcase-check h5"></i></a>
                    </div>
                    <p class="font-14 m-0">Bulan ini : {{ data.merchant_perlu_validasi_bulan_ini }}</p>
                </div>
            </div>
        </div>
    </div> -->
</div>
</template>

<script>
import iziToast from 'izitoast';
import axios from 'axios';

export default {
    name: 'Dashboard',
    // components: {
    //     WidgetsDropdown,
    // },
    created() {
        document.title = "Dashboard - Rudaya ~ Connect The Art";
    },
    mounted() {
        // this.userDetail();
        this.displayData();
    },
    data() {
        return {
            loader: '',
            user: JSON.parse(localStorage.getItem('dataUser') || "[]"),
            data: [],
        }
    },
    methods: {
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        Loader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                loader: 'dots',
                color: '#6777ef'

            });
        },
        displayData() {
            let uri = this.url + `api/v1/admin/dashboard`;
           
            console.log("ini url get data");
            console.log(this.url);
            this.axios.get(uri, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },

            }, ).then(response => {
                console.log(response.data.data);
                // this.loader.hide();
                this.data = response.data.data;

            });
        }
    }
}
</script>
